import React, { useState } from "react";

import styled from "@emotion/styled";

import ShopBaseTemplate from "../../../../components/pages/Shop/ShopBaseTemplate";
import { lastVisitedStorePathKey } from "../../../../components/pages/Shop/ShoppingCart/store";
import { Link } from "../../../../components/shared/LinkComponent";
import NewHeadline from "../../../../components/shared/NewHeadline";
import { storageSession } from "../../../../utils/StorageHandler";
import { getUrlParam } from "../../../../utils/urlParams";

const Centered = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Content = styled.section`
    max-width: 400px;
    padding-bottom: 5em;
`;

const StyledLink = styled(Link)`
    text-decoration: underline;
`;

const ShopBestellungErfolgreichPage = () => {
    // Redirect the user back to the shop storefront.
    const [storefrontUrl] = useState(
        storageSession.get(lastVisitedStorePathKey, "/")
    );

    return (
        <ShopBaseTemplate title="Hoyer Shop - Bestellung erfolgreich">
            <Centered>
                <Content>
                    <NewHeadline lookLike={2}>
                        Vielen Dank für Deine Bestellung
                    </NewHeadline>
                    <p>
                        Dein Auftrag ist bei uns eingegangen und wird in Kürze
                        bearbeitet.
                    </p>
                    {getUrlParam("orderNumber") && (
                        <p>
                            Deine Auftragsnummer lautet:{" "}
                            <strong>{getUrlParam("orderNumber")}</strong>
                        </p>
                    )}
                    <p>Du erhältst eine Bestellbestätigung per E-Mail.</p>
                    <p>
                        Klicke <StyledLink to={storefrontUrl}>hier</StyledLink>{" "}
                        um zurück zur Startseite zu gelangen.
                    </p>
                </Content>
            </Centered>
        </ShopBaseTemplate>
    );
};

export default ShopBestellungErfolgreichPage;
